<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Item Properties</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-toolbar color="secondary" flat dense>
        <v-btn outlined color="toolbarIcon" text to="/item/properties/create">
          <v-icon left dark>mdi-plus</v-icon>New
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row dense>
          <v-col cols="6">
            <v-data-table
              dense
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="itemProperties"
              :search="search"
            >
              <template v-slot:item.ItmsGrpNam="{ item }">
                <v-btn
                  text
                  :to="`/item/properties/${item.id}`"
                  color="flatButton"
                >{{ item.ItmsGrpNam }}</v-btn>
              </template>

              <template v-slot:item.No="{ item }">
                <v-chip>{{item.itg1.length}}</v-chip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    itemProperties: [],
    cqg1: [],
    headers: [
      { text: "Name", value: "ItmsGrpNam" },
      { text: "No. of Properties", value: "No" },
    ],
  }),
  methods: {
    getProperties() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/itemsproperty`)
        .then((res) => {
          self.itemProperties = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getProperties();
  },
};
</script>